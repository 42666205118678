import React from 'react'
import "./Apps.css"
import { Col, Container, Row } from 'react-bootstrap'

const Apps = () => {
    return (
        <Container className='erp-apps'>
            <h1 className='erp-apps-title'>And Integrate with More apps . . .</h1>
            <div className='erp-apps-container'>
                <img src={require("../../../../asset/images/ErpSolutions/apps.png")} />
            </div>
        </Container>
    )
}

export default Apps
