import React from 'react'
import "./Home.css"
import { Hero, ContactUs, Partners, Services, Projects } from '../../Features/Home'
import { Col, Container, Row } from "react-bootstrap"
import arrowRight from "../../asset/images/home/arrowright.svg"
import { Link } from "react-router-dom"

const Home = () => {
    return (
        <div>
            <Hero />
            <div className="netsystem" id='netsystem'>
                <Container>
                    <Row className="net-system-container">
                        <Col lg={6} className="solutions-img">
                            <img id="hand" src={require("../../asset/images/home/Minimal 3d Metaverse Live webinar Instagram Post (1) 1.png")} />
                            <img id="ball" src={require("../../asset/images/home/ball.webp")} />
                        </Col>
                        <Col lg={6}>
                            <div className="net-system" data-aos="fade-right" data-aos-duration="1800">
                                <h1 >AI - Innovation</h1>
                                <p >Experience the transformative power of AI with solutions meticulously tailored to elevate your business. At our company, we empower you to enhance decision-making, streamline processes, and foster innovation through our cutting-edge technology. Harness the potential of advanced artificial intelligence to drive your business forward and achieve new heights of success.
                                </p>
                                <Link  reloadDocument to={"/AiService"} className='home-service-link'>show details</Link>
                            </div>
                        </Col>
                    </Row>
                    <div className='services-arrow'>
                        <img src={arrowRight}  data-aos="fade-down" data-aos-duration="1800" />
                    </div>
                </Container>
            </div>
        

            <Services />
       

     
            <Projects />
         
                
                
            
            <Partners />
            <ContactUs />

        </div>
    )
}

export default Home
