import React from 'react'
import "./Module.css"
import module from "../../../../asset/images/ErpSolutions/module.svg"

const Module = ({ title }) => {
    return (
        <div className='erp-module'>
            <h1 className='erp-module-title'>{title}</h1>
            <img src={module} />
        </div>
    )
}

export default Module
