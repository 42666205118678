import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../asset/images/logos/logo.svg";
import enginiaWhite from "../../asset/images/logos/enginia-white.svg";
import { HashLink } from 'react-router-hash-link'; // Import HashLink


const Footer = ({}) => {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="footer-content">
            <div className="footer-text" id="footer-logo">
              <Link to={"/home"} className="footer-logos">
                <img src={logo} />
                <img src={enginiaWhite} />
              </Link>
              <p></p>
            </div>
            <div className="footer-text" id="footer-links">
              <h3 className="text-uppercase">Learn More</h3>
              <Link to={"/about"}>About Us</Link>
              <HashLink smooth to={"/home#contact"}>
                  Contact Us
                </HashLink>
              <Link to={"/ourteam"}>Our Team</Link>
            </div>
            <div className="footer-text"  id="footer-links">
              <h3 className="text-uppercase">SERVICES</h3>
              <Link reloadDocument smooth to={"/AiService"}>AI - Innovation</Link>
              <Link reloadDocument to="/erpsolutions">ERP Solutions</Link>
              <Link reloadDocument to={"/MobileApps"}>Mobile Application Development</Link>
              <Link reloadDocument to={"/WebApplications"}>Web Application Development</Link>
            </div>
            {/* <div className="footer-text" id="footer-links">
              <h3 className="text-uppercase">support</h3>
              <a href="#">Terms & conditions</a>
              <a href="#">Privacy policy</a> 
            </div> */}
            <div className="footer-text" id="footer-touch">
              <h3 className="text-uppercase">get in touch</h3>
              <div className="footer-touch-text">
               <p>Meydan Grandstand, 6th Floor, Meydan Rd, Nad Al Sheba 1, <b>Dubai, UAE</b></p>
              </div>
  
            </div>
          </div>
          <div className="footer-down">
            <div className="footer-links">
              <div className="links-text">
                {/* <p>
                  <a href="#">Privacy policy</a>
                </p>
                <p>
                  <a href="#">Terms and Conditions</a>
                </p> */}
              </div>

              <div className="links">
                {/* <svg
                  width="25"
                  height="20"
                  viewBox="0 0 25 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M24.2276 2.53768C23.3715 2.917 22.4519 3.17331 21.4851 3.28915C22.4827 2.69227 23.2289 1.75285 23.5848 0.646171C22.6476 1.20285 21.6218 1.59469 20.5522 1.80465C19.8329 1.03664 18.8801 0.527582 17.8419 0.356524C16.8036 0.185466 15.7379 0.361975 14.8103 0.858648C13.8826 1.35532 13.1448 2.14437 12.7116 3.10329C12.2783 4.06221 12.1737 5.13734 12.4141 6.16178C10.5151 6.06643 8.65737 5.57285 6.96146 4.71307C5.26556 3.85328 3.7694 2.64651 2.57007 1.17108C2.15999 1.87847 1.92419 2.69863 1.92419 3.57211C1.92373 4.35844 2.11737 5.13272 2.48793 5.82627C2.85849 6.51981 3.3945 7.11116 4.04842 7.54786C3.29005 7.52373 2.54841 7.31881 1.88523 6.95017V7.01168C1.88516 8.11454 2.26664 9.18345 2.96496 10.0371C3.66328 10.8907 4.63542 11.4764 5.71643 11.6948C5.01292 11.8852 4.27533 11.9133 3.55939 11.7768C3.86439 12.7258 4.4585 13.5556 5.25854 14.1501C6.05858 14.7446 7.02451 15.0741 8.02109 15.0924C6.32933 16.4204 4.24002 17.1408 2.08925 17.1376C1.70826 17.1377 1.3276 17.1155 0.949219 17.071C3.13237 18.4747 5.67372 19.2197 8.26919 19.2168C17.0552 19.2168 21.8583 11.9398 21.8583 5.62868C21.8583 5.42363 21.8532 5.21654 21.844 5.0115C22.7782 4.33586 23.5847 3.49921 24.2255 2.54075L24.2276 2.53768V2.53768Z" />
                </svg> */}
                <a target="_blank" href="https://www.linkedin.com/company/enginia/">
                  <svg
                    width="28"
                    height="24"
                    viewBox="0 0 28 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.36463 7.79908V23.998H0.350163V7.79908H6.36463ZM6.74702 2.79803L6.74925 2.89601C6.74925 3.64486 6.39473 4.31874 5.8284 4.79065L5.82617 4.79165C5.49257 5.06529 5.10176 5.27688 4.67637 5.41417C4.25098 5.55146 3.79946 5.61173 3.34792 5.5915H3.35573H3.31894C2.87778 5.61026 2.43694 5.549 2.02279 5.4114C1.60865 5.27379 1.22972 5.06266 0.90869 4.79065L0.909805 4.79165C0.611709 4.5337 0.376926 4.2228 0.220474 3.87882C0.0640225 3.53484 -0.0106531 3.16537 0.0012239 2.79403V2.79803L0.000109079 2.72904C0.000109079 1.96718 0.361312 1.28131 0.937675 0.800405L0.939905 0.799405C1.6094 0.252565 2.4935 -0.0336653 3.39809 0.00355604H3.3914C3.83461 -0.0158711 4.27767 0.0444463 4.69448 0.180955C5.11129 0.317464 5.49342 0.527403 5.81837 0.798405L5.81614 0.796406C6.1109 1.05052 6.34531 1.35547 6.50528 1.69292C6.66526 2.03037 6.74748 2.39335 6.74702 2.76003V2.80202V2.80003V2.79803ZM27.9978 14.7158V23.998H22.0023V15.3346C22.0632 14.384 21.8008 13.4391 21.2497 12.6252L21.2631 12.6462C21.0038 12.3187 20.6544 12.057 20.2488 11.8867C19.8432 11.7164 19.3953 11.6432 18.9488 11.6743H18.9577C18.26 11.652 17.5762 11.8533 17.029 12.2422L17.0357 12.2382C16.5305 12.6034 16.1351 13.0759 15.8852 13.613L15.8763 13.636C15.7236 14.0657 15.6561 14.5161 15.6767 14.9667V14.9597V24H9.68123C9.70576 19.6522 9.71802 16.1268 9.71802 13.424C9.71802 10.7212 9.71207 9.10849 9.70018 8.58593L9.68235 7.80108H15.6779V10.1546H15.6411C15.8796 9.80969 16.126 9.51075 16.3969 9.2308L16.388 9.2398C16.7013 8.93086 17.0379 8.65191 17.4003 8.40196L17.4181 8.38996C17.882 8.07073 18.4083 7.83202 18.9699 7.6861L19.0034 7.6791C19.6541 7.50882 20.3288 7.4233 21.0067 7.42515H21.0948H21.0903C22.0249 7.38985 22.9568 7.53706 23.8216 7.85659C24.6865 8.17613 25.4636 8.66037 26.0992 9.2758L26.1026 9.2798C27.3675 10.5169 28 12.3285 28 14.7148L27.9978 14.7158Z" />
                  </svg>
                </a>
                
                <a href="https://www.facebook.com/profile.php?id=61559949929536"  target="_blank">
                  <svg
                    width="12"
                    height="24"
                    viewBox="0 0 12 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3.06332 24V12.7385H0V8.68382H3.06332V5.2206C3.06332 2.49917 4.87284 0 9.04235 0C10.7305 0 11.9788 0.15732 11.9788 0.15732L11.8805 3.9437C11.8805 3.9437 10.6074 3.93166 9.21814 3.93166C7.71455 3.93166 7.47365 4.60522 7.47365 5.72316V8.68382H12L11.8031 12.7385H7.47365V24H3.06332Z" />
                  </svg>
                </a>
        
                {/* <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.4966 7.9983C10.2014 7.9983 8.32828 9.79651 8.32828 12C8.32828 14.2035 10.2014 16.0017 12.4966 16.0017C14.7919 16.0017 16.665 14.2035 16.665 12C16.665 9.79651 14.7919 7.9983 12.4966 7.9983ZM24.9986 12C24.9986 10.3429 25.0142 8.70077 24.9173 7.04665C24.8204 5.12536 24.3638 3.4202 22.9004 2.01525C21.4338 0.607302 19.6607 0.172009 17.6594 0.0789456C15.9333 -0.0141172 14.2228 0.000892936 12.4998 0.000892936C10.7736 0.000892936 9.06313 -0.0141172 7.34013 0.0789456C5.33881 0.172009 3.56265 0.610305 2.09918 2.01525C0.632595 3.42321 0.179172 5.12536 0.0822335 7.04665C-0.0147052 8.70377 0.000930124 10.3459 0.000930124 12C0.000930124 13.6541 -0.0147052 15.2992 0.0822335 16.9533C0.179172 18.8746 0.635722 20.5798 2.09918 21.9847C3.56577 23.3927 5.33881 23.828 7.34013 23.9211C9.06626 24.0141 10.7768 23.9991 12.4998 23.9991C14.2259 23.9991 15.9364 24.0141 17.6594 23.9211C19.6607 23.828 21.4369 23.3897 22.9004 21.9847C24.3669 20.5768 24.8204 18.8746 24.9173 16.9533C25.0174 15.2992 24.9986 13.6571 24.9986 12V12ZM12.4966 18.1572C8.94743 18.1572 6.08305 15.4073 6.08305 12C6.08305 8.5927 8.94743 5.84284 12.4966 5.84284C16.0458 5.84284 18.9102 8.5927 18.9102 12C18.9102 15.4073 16.0458 18.1572 12.4966 18.1572ZM19.1729 7.02864C18.3442 7.02864 17.675 6.38621 17.675 5.59067C17.675 4.79513 18.3442 4.1527 19.1729 4.1527C20.0016 4.1527 20.6708 4.79513 20.6708 5.59067C20.671 5.77957 20.6324 5.96667 20.5572 6.14124C20.4821 6.31581 20.3717 6.47442 20.2326 6.608C20.0935 6.74157 19.9282 6.84748 19.7464 6.91967C19.5646 6.99185 19.3697 7.02888 19.1729 7.02864V7.02864Z" />
                </svg> */}
              </div>
            </div>
            <div className="footer-down-para">
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Voluptate, fuga. Ex at maxime eum odio quibusdam pariatur
                expedita explicabo harum! Consectetur ducimus delectus nemo,
                totam odit!
              </p> */}
              <p>Copyright @ 2024. ENGINIA SOLUTIONS LLC</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
