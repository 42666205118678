import React, { useState, useEffect } from 'react'
import "./Projects.css"
import { Project} from "../../index"
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Projects = () => {


      // State to store the projects data and loading status
      const [projects, setProjects] = useState([]);
      const [loading, setLoading] = useState(true);
  
      // Fetch projects data from the API
      useEffect(() => {
          fetch('https://admin.enginia.ae/api/v1/projects?limit=6')
              .then(response => response.json())
              .then(data => {
                  if (data && data.data) {
                      setProjects(data.data);
                  }
              })
              .catch(error => console.error('Error fetching projects:', error))
              .finally(() => setLoading(false)); // Set loading to false when fetch completes
      }, []);

    return (
        <Container className="projects-container">
            <h1>Our Latest projects</h1>
            <p>
                Our solutions have empowered <span>over +125 clients across 12 countries</span> to reach their goals across diverse industries. From fintech and ecommerce to communication, real estate, entertainment, logistics
            </p>
            <Row>
                {
                    projects.map((item, index) => (
                        <Project 
                            key={index} 
                            title={item.name}
                            para={item.description_short}
                            image={"https://admin.enginia.ae" + item.image}
                            link={`/ProjectDetails/${item.id}`}

                        />
                    ))
                }
             
           
            </Row>
            <Link to='/projectslist' className="projects-link">All Projects</Link>
        </Container>
    )
}

export default Projects
