import React from 'react'
import "./TechnologyStack.css"
import { Col, Container, Row } from 'react-bootstrap'


const TechnologyStack = ({ data, url }) => {

    if (!data || !data.technologies || data.technologies.length === 0) {
        return null;
    } 
    return (
        <div  className='stack-div'>
            <div className='stack-title' data-aos="fade-down" data-aos-duration="1800">Technology Stack</div>
            <Row className='stack-row'>
                {data.technologies.technologies_with_type.map(type => (
                    <div className='col-12 col-md-4'>
                        <div data-aos="zoom-in" data-aos-duration="1800" className='stack-box'>
                            <div>{type.technology_type}</div>
                            <div className='lang'>
                            {type.technologies.map((tech, index) => (
            <span key={tech.id}>
              {tech.technology}
              {index !== type.technologies.length - 1 && ', '}
            </span>
          ))}
                            </div>
                        </div>
                    </div>
                ))}
            </Row>
        </div>
    )
}

export default TechnologyStack