import React from 'react'
import "./Para.css"

const Para = () => {
    return (
        <div className='our-team-para'>
            <h1 className='our-team-title'>Meet Our Founder</h1>
            <p className='our-team-desc'>At Enginia Solutions LLC, we take pride in our team of dedicated professionals who are at the forefront of AI innovation, custom website development, mobile applications, and ERP solutions. Our experts bring together a wealth of experience, creativity, and technical expertise to deliver cutting-edge solutions tailored to your business needs.</p>
        </div>
    )
}

export default Para
