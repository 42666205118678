import React from 'react'
import "./Partners.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import '@splidejs/splide/css';
import { Container } from 'react-bootstrap';

const Partners = () => {
    return (
        <Container className="Partner">
                <div className="Partner-container">
                    <h1>Our Clients</h1>
                    <p>Develop customer-first experiences through Progressive Web Apps, and Native Apps.</p>
                </div>
                <Splide
                    options={{
                        type: "loop",
                        arrows: false,
                        pagination: false,
                        gap: "90px",
                        drag: "free",
                        perPage: 4,
                        autoWidth: true,
                        autoScroll: {
                            pauseOnHover: false,
                            pauseOnFocus: false,
                            rewind: false,
                            speed: 1,
                            }
                        }}
                    extensions={{ AutoScroll }}
                >
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/1.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/2.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/3.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/4.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/5.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/6.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/Sauidetqaan.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/alhokair.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/genius.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/logo-horizontal-colour 1.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/saudiex.png")} className="splide-img" />
                        </SplideSlide>
                        <SplideSlide >
                            <img src={require("../../../../asset/images/partner/aladel.png")} className="splide-img" />
                        </SplideSlide>
                </Splide>
        </Container>

    )
}

export default Partners
