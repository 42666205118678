import React, { useState, useEffect } from 'react'
import "./ProjectsList.css"
import { Project} from "../../../Home/index"
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ProjectsList = () => {


      // State to store the projects data and loading status
      const [projects, setProjects] = useState([]);
      const [loading, setLoading] = useState(true);
  
      // Fetch projects data from the API
      useEffect(() => {
          fetch('https://admin.enginia.ae/api/v1/projects?category_id=7c035f07-47c6-42c1-a3f1-07ac8d779388,919432f8-8755-477c-baa1-e9a9ce5817fd')
              .then(response => response.json())
              .then(data => {
                  if (data && data.data) {
                      setProjects(data.data);
                  }
              })
              .catch(error => console.error('Error fetching projects:', error))
              .finally(() => setLoading(false)); // Set loading to false when fetch completes
      }, []);

    return (
        <Container className="projects-container">
            <h1>Our Latest Applications</h1>
            <p>
                Our solutions have led to the development of  <b>+20</b> applications across 5 countries, empowering clients to reach their goals in diverse industries such as fintech, ecommerce, communication, real estate, entertainment, and logistics.
            </p>
            <Row>
                {
                    projects.map((item, index) => (
                        <Project 
                            key={index} 
                            title={item.name}
                            para={item.description_short}
                            image={"https://admin.enginia.ae" + item.image}
                            link={`/ProjectDetails/${item.id}`}
                        />
                    ))
                }
             
           
            </Row>
            <Link to='/projectslist' className="projects-link">All Projects</Link>
        </Container>
    )
}

export default ProjectsList
