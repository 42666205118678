import React, { useEffect } from 'react';
import "./Hero.css";
import { Col, Container, Row } from 'react-bootstrap';
import googleplay from '../../../../asset/images/svg/googleplay.svg';
import appstore from '../../../../asset/images/svg/appstore.svg';
import HeroImg from '../../../../asset/images/ProjectDetails/pro_hero_img.png';

const Hero = ({ data, url }) => {
  useEffect(() => {
    console.log(data);
  }, [data]);

  if (!data || !data.categories || data.categories.length === 0) {
    return null; // or return a default component
  }

  return (
    <div className="hero-div">
      <img
        src={url + data.details_image}
        alt="hero-img"
        className="hero-background"
      />
      <Container>
        <Row className='project-details-container'>
          <div className="col-12 col-md-6">
            <div className="project-intro-div">
              <div className="project-type-div">
                {data.categories.map((type) =>
                  type.name === "Website" ? (
                    <div key={type.id}>
                      <div className="project-type-btn">
                        {type.name}
                      </div>
                    </div>
                  ) : (
                    <div key={type.id} className="project-type-btn">
                      {type.name}
                    </div>
                  )
                )}
              </div>
              <div className="project-title">{data.name}</div>
              <div className="project-des">{data.description_short}</div>
              <div className='app-web-links'>
                {(data.play_store || data.app_store) ? (
                  <>
                    <div className="apps">
                      {data.play_store && (
                        <a href={data.play_store} target="_blank">
                          <img src={googleplay} alt="googleplay" className='app-image' />
                        </a>
                      )}
                      {data.app_store && (
                        <a href={data.app_store} target="_blank">
                          <img src={appstore} alt="appstore" className='app-image' />
                        </a>
                      )}
                    </div>
                    <a href={data.website} className="btn-website" target="_blank">
                      GO TO WEBSITE
                    </a>
                  </>
                ) : (
                  <a href={data.website} className="btn-website-noapp" target="_blank">
                    GO TO WEBSITE
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 hero-img-col"></div>
        </Row>

        {data.description && (
          <div className="app-container">
            <div className="app-title">Description</div>
            <div
              className="app-text"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Hero;