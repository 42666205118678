import React from 'react'
import "./OurTeam.css"
import { Header } from '../../Features/AboutUs'
import { ContactUs } from '../../Features/Home'
import { Card, Para } from '../../Features/OurTeam'
import { Container, Row } from 'react-bootstrap'

export const OurTeam = () => {
    return (
        <div>
            <Header
                firstTitle={"Home"}
                title={"/ Our Team"}
                para={"Our team"}
            />
            <Container>
                <Para />
                <div className='team'>
                    <h1 className='our-team'>Founders & Mangers</h1>
                        <Row>
                            <Card 
                                image={require("../../asset/images/ourteam/muayad.png")}
                                name={"MHD.Muayad Dubany"}
                                title={"CO-Partner"}
                            />
                            <Card 
                                image={require("../../asset/images/ourteam/houssam.png")}
                                name={"MHD.Houssam Koudsi"}
                                title={"CO-Partner"}
                            />
                            <Card 
                                image={require("../../asset/images/ourteam/rafif.png")}
                                name={"Rafif Zinia"}
                                title={"Artificial Intelligence Engineer"}
                                para={"Project Manager"}
                            />
                        </Row>
                </div>
                <div className='team'>   
                    <h1 className='our-team'>Team</h1>
                    <Row>
                        <Card 
                            image={require("../../asset/images/ourteam/omar.png")}
                            name={"Omar Ghouka"}
                            title={"Data Science Engineer"}
                            para={"Senior Software Engineer / Full stack Developer"}
                        />
                        <Card 
                            image={require("../../asset/images/ourteam/nofal.png")}
                            name={"Omar Noufal"}
                            title={"Intelligence Systems Engineer"}
                            para={"Senior Backend Developer / AI Engineer"}
                        />
                        <Card 
                            image={require("../../asset/images/ourteam/rk.png")}
                            name={"Raghad Kadry"}
                            title={"Software Engineer"}
                            para={"Senior Backend Developer"}
                        />
                        <Card 
                            image={require("../../asset/images/ourteam/rs.png")}
                            name={"Rawan Snbol"}
                            title={"Software Engineer"}
                            para={"Full Stack & Mobile App developer"}
                        />
                        <Card 
                            image={require("../../asset/images/ourteam/haidara.png")}
                            name={"Elias Dahdal"}
                            title={"Machine Learning Engineer"}
                            para={"AI & ERP Developer"}
                        />
                        <Card 
                            image={require("../../asset/images/ourteam/es.png")}
                            name={"Esraa Hasan"}
                            title={"Software Engineer"}
                            para={"Frontend Developer"}
                        />
                        <Card 
                            image={require("../../asset/images/ourteam/hosam.png")}
                            name={"Hosam Akkad"}
                            title={"Software Engineer"}
                            para={"Frontend Developer"}
                        />
                    </Row>
                </div>
            </Container>
           
            <ContactUs />
        </div>
    )
}

export default OurTeam