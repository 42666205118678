import React from "react";
import "./WebsiteService.css";
import {
Hero,
AboutService,
TechnologyStack,
WebsiteServices,
ProjectsList

} from "../../Features/WebsiteService";

const WebsiteService = () => {
  return (
    <div className="WebsiteService">
        <Hero/>
        <AboutService/>
        <TechnologyStack/>
        <WebsiteServices/>
        <ProjectsList/>
    </div>
  );
};

export default WebsiteService;
