import React from 'react'
import "./Card.css"
import { Col } from 'react-bootstrap'

const Card = ({ image, title, name, para }) => {
    return (
        <Col lg={3} md={4} sm={6} className='team-card'>
            <div className='team-card-image'>
                <img src={image} className='team-card-img' />
            </div>
            <h1 className='team-card-name'>{name}</h1>
            <h1 className='team-card-title'>{title}</h1>
            <p className='team-card-para'>{para}</p>
        </Col>
    )
}

export default Card
