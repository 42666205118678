import React from 'react'
import "./SystemData.css"
import { Container } from 'react-bootstrap'

const SystemData = () => {
    return (
        <Container className='system-data'>
            <h1 data-aos="fade-down" data-aos-duration="2200" className='system-data-title'>Analyze your system data</h1>
            <p data-aos="fade-down" data-aos-duration="2000" className='system-data-para'>
                Leverage our integrated ERP solution to analyze your system data comprehensively. Gain insights into user behavior, engagement, and performance metrics to make informed business decisions.
            </p>
            <div data-aos="fade-down" data-aos-duration="1800" className='system-data-video'>
                {/* <iframe className='system-data-iframe' src="https://www.youtube.com/embed/f4gPxUay7v0?si=i1K8mwoY-VI_AkwJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                <img className='system-data-iframe' src={require("../../../../asset/images/ErpSolutions/dashboard.webp")} />
            </div>
        </Container>
    )
}

export default SystemData
