import React from 'react'
import "./ProjectsList.css"
import { Header , Projects } from '../../Features/ProjectsList'


const ProjectsList = () => {
    return (
        <div>
            <Header></Header>
            <Projects></Projects>
        </div>
    )
}

export default ProjectsList
