import React, { useState } from 'react';
import "./ContactUs.css";
import { Container, Col, Row, Form } from "react-bootstrap";
import location from "../../../../asset/images/ErpSolutions/Contact/location.svg";
import phone from "../../../../asset/images/ErpSolutions/Contact/phone.svg";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        companyName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const payload = {
            full_name: formData.fullName,
            company_name: formData.companyName,
            email: formData.email,
            number: formData.phoneNumber,
            message: formData.message,
            contacted_back: false
        };

        try {
            const response = await fetch('https://admin.enginia.ae/api/v1/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                // Handle successful response
                setIsSubmitted(true);
            } else {
                // Handle error response
                alert('Failed to send message');
            }
        } catch (error) {
            // Handle network error
            alert('An error occurred. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='erp-contact-container' >
            <Container className='erp-contact-content'>
                <Row>
                    <Col lg={6}>
                        <h1 className='erp-contact-title' id="contact">Contact Us</h1>
                        <p className='erp-contact-para'>Let's create the best possible solution together. Contact us, we can help</p>
                        <ul className='erp-contact-list'>
                            <li className='erp-contact-item'>
                                <img src={location} alt="location" />
                                <label>Dubai - United Arab Emirates</label>
                            </li>
                            <li className='erp-contact-item'>
                                <img src={phone} alt="phone" />
                                <label>(+971) 56 551 5489</label>
                            </li>
                        </ul>
                        {!isSubmitted && (
                            <img
                                className='erp-contact-img'
                                src={require("../../../../asset/images/ErpSolutions/Contact/contact.png")}
                                alt="contact"
                            />
                            )}
                    </Col>
                    <Col lg={1} className='contact-midline-container'>
                        <div className='contact-midline'></div>
                    </Col>
                    <Col lg={5}>
                        {isSubmitted ? (
                            <div className="success-message">
                                <h2>Thank you!</h2>
                                <p>Your message has been sent successfully. We will get back to you shortly.</p>
                            </div>
                        ) : (
                            <Form onSubmit={handleSubmit} style={{ opacity: isSubmitting ? 0.5 : 1 }}>
                                <div className='contact-field'>
                                    <p className='contact-para'>Full Name<span>*</span></p>
                                    <input
                                        className='contact-input'
                                        type='text'
                                        name='fullName'
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        required
                                    />
                                </div>
                                <div className='contact-field'>
                                    <p className='contact-para'>Company Name<span>*</span></p>
                                    <input
                                        className='contact-input'
                                        type='text'
                                        name='companyName'
                                        value={formData.companyName}
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        required
                                    />
                                </div>
                                <div className='contact-field'>
                                    <p className='contact-para'>Email<span>*</span></p>
                                    <input
                                        className='contact-input'
                                        type='email'
                                        name='email'
                                        value={formData.email}
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        required
                                    />
                                </div>
                                <div className='contact-field'>
                                    <p className='contact-para'>Phone Number<span>*</span></p>
                                    <Row className='phone-container'>
                                        <Col xl={2} sm={3} xs={3} className='form-input-phone'>
                                            <select className='form-input-select' id='form-input-phone' disabled={isSubmitting}>
                                                <option>+971</option>
                                                <option>+1</option>
                                            </select>
                                        </Col>
                                        <Col xl={10} sm={9} xs={9} className='form-input-number'>
                                            <input
                                                className='form-input'
                                                id='form-input-number'
                                                type='text'
                                                name='phoneNumber'
                                                value={formData.phoneNumber}
                                                onChange={handleChange}
                                                placeholder='11223344548'
                                                disabled={isSubmitting}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className='contact-field'>
                                    <p className='contact-para'>Your message</p>
                                    <textarea
                                        className='contact-input'
                                        rows={4}
                                        name='message'
                                        value={formData.message}
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        required
                                    ></textarea>
                                </div>
                                <input
                                    type='submit'
                                    value="Send message"
                                    className='erp-contact-submit'
                                    disabled={isSubmitting}
                                />
                            </Form>
                        )}
                    </Col>
                </Row>
                
            </Container>
           
        </div>
    );
};

export default ContactUs;
