import React from "react";
import "./AiService.css";
import {
  Hero,
  AboutService,
  AiServices,
  IndustryServices,
} from "../../Features/AiService";

const AiService = () => {
  return (
    <div>
        <Hero/>
        <AboutService/>
        <AiServices/>
        <IndustryServices/>
    </div>
  );
};

export default AiService;
