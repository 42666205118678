import React, { useEffect, useState } from 'react';
import "./Projects.css";
import { Container, Col, Row, Spinner } from 'react-bootstrap';
import { Project } from '../../index';
import { Link } from 'react-router-dom';

const Projects = () => {
    // State to store the projects data and loading status
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch projects data from the API
    useEffect(() => {
        fetch('https://admin.enginia.ae/api/v1/projects')
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    setProjects(data.data);
                }
            })
            .catch(error => console.error('Error fetching projects:', error))
            .finally(() => setLoading(false)); // Set loading to false when fetch completes
    }, []);

    return (
        <div className='projectsList-Projects'>
            <Container>
                <div className="projectsList-container">
                    {/* Conditionally render loading spinner or projects */}
                    {loading ? (
                        <div className="loading-spinner">
                            <Spinner animation="border"  />
                        </div>
                    ) : (
                        <Row>
                            {/* Render Project components with fetched data */}
                            {projects.map(project => (
                                <Col key={project.id} xs={12} lg={6}>
                                    <Link to={`/ProjectDetails/${project.id}`}>
                                        <Project
                                            image={"https://admin.enginia.ae" + project.image}
                                            title={project.name}
                                            des={project.description_short}
                                        />
                                    </Link >
                                </Col>
                            ))}
                        </Row>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default Projects;
