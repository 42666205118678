import React from 'react'
import "./Service.css"

const Service = ({ image, title, para }) => {
    return (
        <div  className="erp-services" data-aos="fade-down" data-aos-duration="1800">
            <img className='erp-service-img' src={image} />
            <h1 className="erp-service-title">{title}</h1>
            <p className="erp-service-para">{para}</p>
        </div>
    )
}

export default Service
