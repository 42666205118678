import React from 'react'
import "./Hero.css"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Color1 from "../../../../asset/images/vectors/color1.webp";
import Color2 from "../../../../asset/images/vectors/color2.webp";
import Typewriter from 'typewriter-effect';
import sendIcon from "../../../../asset/images/AiService/send_icon.svg"
import enginiaIconAi from "../../../../asset/images/AiService/ai_engina_icon.svg"


const Hero = () => {

    return (
        <div>
            <div className="color-div1">
                <img id="color1" src={Color1} alt="color1" />
                <img id="color2" src={Color2} alt="color2" />
            </div>
            <div className='ai-hero'>
                <div className='ai-hero-container container'>

                    <div className='ai-hero-content'>

                        <Row>
                            <Col xs={12} md={6}>
                                <div className='ai-hero-text-container' data-aos="fade-right" data-aos-duration="1800">
                                    <div className='ai-hero-text-content'>
                                        <div className='ai-hero-text-title'>
                                            AI -
                                            <span className='ai-hero-text-title-words'>
                                                <Typewriter
                                                    options={{
                                                        strings: ["Progress", "Creativity", "Solutions", "Development", "Revolution"],
                                                        autoStart: true,
                                                        loop: true,
                                                        deleteSpeed: 50,
                                                    }}
                                                />
                                            </span>

                                        </div>
                                        <div className='ai-hero-text-des'>
                                            Boost your business with our AI Innovation service. We automate tasks, improve interactions, and increase efficiency using AI. With machine learning and NLP, we make your apps smarter and more effective.
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} md={6} >
                                <div className='ai-hero-image-container' data-aos="fade-left" data-aos-duration="1800">
                                    <div className='ai-hero-image-content'>

                                        <div className='ai-hero-floating-btn-1'>
                                            <img src={sendIcon} alt='send-icon' />
                                        </div>

                                        <div className='ai-hero-floating-btn-2'>
                                            <img src={enginiaIconAi} alt='enginia-icon' />
                                        </div>

                                        <img src={require('../../../../asset/images/AiService/hero-image.webp')} alt='ai-hero' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Hero
