import React from 'react'
import "./Header.css"
import { Container } from 'react-bootstrap'

const Hero = () => {
    return (
        <div className='projectsList-header'>
            <Container>
                <div className="projectsList-header-container">
                    <div className='projectsList-header-content'>
                        <h2>Our Latest Projects</h2>
                        <span>Our solutions have empowered <b>over +125 clients across 12 countries</b>  to reach their goals across diverse industries. From fintech and ecommerce to communication, real estate, entertainment, logistics</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Hero
