import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { NavPage, Footer, ScrollTop, Loading } from './Layouts/index';
import { ProjectDetailsPage, ErpSolutions, ProjectsList, Home, AboutUs, OurTeam, AiService ,MobileService,WebsiteService } from "./Pages/index";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import lottieLoading from "./asset/loading/loading-1.json";

function App() {
  const [loading, setLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const imagesToLoad = useRef([]);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(false);

  useEffect(() => {
    AOS.init({
      once: true,
    });



    const handleImageLoad = () => {
      // Check if all images are loaded
      if (imagesToLoad.current.every(img => img.complete)) {
        setShowContent(true);
      }
    };

    // Find all images and attach onLoad event
    const imgElements = document.querySelectorAll('img');
    imgElements.forEach((img) => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.onload = handleImageLoad;
        imagesToLoad.current.push(img);
      }
    });

    // Check if there are no images to load
    if (imagesToLoad.current.length === 0) {
      setShowContent(true);
    }

    // Ensure the loading animation lasts at least 2 seconds
    const timer = setTimeout(() => {
      setMinimumTimeElapsed(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showContent && minimumTimeElapsed) {
      setFadeOut(true); // Start fading out
      setTimeout(() => {
        setLoading(false);
      }, 1000); // Allow time for the fade-out animation
    }
  }, [showContent, minimumTimeElapsed]);

  return (
    <>
      {loading ? (
        <div className={`loading-container ${fadeOut ? 'fade-out' : ''}`}>
          <Loading animationData={lottieLoading} animationSpeed={0.5} />
        </div>
      ) : (
        <div className={`App ${showContent ? 'show-content' : ''}`}>
          <BrowserRouter>
            <ScrollTop />
            <NavPage />
            <Routes>
              <Route path="/ProjectDetails/:id" element={<ProjectDetailsPage />} />
              <Route path="/ProjectsList" element={<ProjectsList />} />
              <Route index element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path='/about' element={<AboutUs />} />
              <Route path="/erpsolutions" element={<ErpSolutions />} />
              <Route path="/AiService" element={<AiService />} />
              <Route path="/MobileApps" element={<MobileService />} />
              <Route path="/WebApplications" element={<WebsiteService />} />
              <Route path="/ourteam" element={<OurTeam />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
      )}
    </>
  );
}

export default App;