import React, { useEffect, useState } from 'react';
import "./AboutUs.css";
import { ContactUs } from '../../Features/ErpSolutions';
import { Header, Para } from '../../Features/AboutUs';
import { Container } from 'react-bootstrap';
import horizontal from "../../asset/images/aboutus/horizontal-about.svg";
import column from "../../asset/images/aboutus/column-about.svg";

const AboutUs = () => {
  const [imageSrc, setImageSrc] = useState(horizontal);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImageSrc(column);
      } else {
        setImageSrc(horizontal);
      }
    };

    // Initial check
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Header
        firstTitle={"Home"}
        title={"/ About Us"}
        para={"About us"}
      />
      <Container>
        <div className='about-page-img'>
          <img src={imageSrc} alt="about-us" />
        </div>
      </Container>
      <Container className='border-top-section'>
        <Para 
          title={"Our Vision"}
          desc={"At Enginia Solutions, we envision a future where cutting-edge AI and advanced web and mobile applications seamlessly integrate into every professional field, empowering businesses and individuals to reach new heights of innovation, efficiency, and success. Our goal is to be the global leader in delivering transformative technological solutions that drive progress and elevate human potential."}
        />
        <Para 
          title={"Our Mission"}
          desc={"Our mission at Enginia Solutions is to harness the power of artificial intelligence and advanced software technologies to provide innovative, reliable, and scalable solutions for professionals across diverse industries. We are committed to delivering exceptional value through continuous innovation, personalized customer service, and a deep understanding of our clients' unique challenges and opportunities. By fostering a culture of excellence and collaboration, we strive to make technology an enabler of progress and a catalyst for change."}
        />
      </Container>
      <Container className='border-top-section'>
        <Para 
          title={"About us"}
          desc={"Enginia Solutions is a pioneering technology company dedicated to revolutionizing professional fields with advanced AI and state-of-the-art web and mobile applications. Founded on the principles of innovation, integrity, and customer-centricity, we specialize in developing bespoke solutions that cater to the specific needs of businesses and professionals."}
          desc2={"Our team of seasoned experts brings together a wealth of experience in artificial intelligence, software engineering, and industry-specific knowledge, enabling us to deliver tailored solutions that drive tangible results. From intelligent automation and data analytics to user-friendly mobile applications and robust web platforms, our offerings are designed to enhance productivity, streamline operations, and foster growth."}
          desc3={"At Enginia Solutions, we believe that technology should not only solve problems but also create new opportunities. We are passionate about helping our clients navigate the complexities of the digital landscape and achieve their strategic goals. With a relentless focus on quality, innovation, and customer satisfaction, we are committed to being your trusted partner in technological advancement."}
          desc4={"Join us on a journey of transformation and discover how Enginia Solutions can empower your business to thrive in the digital age."}
        />
      </Container>
      <ContactUs />
    </div>
  );
}

export default AboutUs;
