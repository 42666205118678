import React from 'react'
import "./Integrate.css"
import { Col, Row } from 'react-bootstrap'

const Integrate = ({ image, arrow, titleImg, para, title, dir, flexEnd }) => {
    return (
        <Row className='integrate' style={{ flexDirection: `${dir}`}}>
            <Col md={6} className={flexEnd ? "integrate-image-end" : ""}>
                <div data-aos="fade-left" data-aos-duration="2200" className='integrate-image'>
                    <img  className='integrate-img' src={image} />
                </div>
            </Col>
            <Col md={6} data-aos="fade-right" data-aos-duration="2200">
                <div  className='integrate-title'>
                    <h1 className='integrate-title-h'>{title}</h1>
                    <img src={titleImg} className='integrate-title-img' />
                </div>
                <p className='integrate-para'>{para}</p>
            </Col>
            <div data-aos="fade-down" data-aos-duration="1500">
            <img src={arrow} className='integrate-arrow' />
            </div>

        </Row>
    )
}

export default Integrate
