import React from 'react'
import "./Service.css"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

const Service = ({ para, title, secondPara, arrow, image, dir, flexDir, classImg, link, showLink, id }) => {
    return (
        <Row className='home-service' style={{ flexDirection: `${flexDir}`}} >
            <Col lg={6} data-aos="fade-left" data-aos-duration="1800">
                <h1 className='home-service-title'>{title}</h1>
                <p className='home-service-para'>{para}</p>
                {
                    secondPara && <p className='home-service-para'>{secondPara}</p>
                }
                {
                    showLink && <Link reloadDocument to={`${link}`} className='home-service-link'>show details</Link>
                }
            </Col>
            <Col lg={6} style={{ position: 'relative', zIndex: '-1'}} data-aos="fade-right" data-aos-duration="2200" className='home-service-image a' >
                <img src={image} className={`${classImg} home-service-img`} style={{ [dir]: '-20%' }} />
            </Col>
            <div  className='services-arrow' id={`${id}`}>
                <img src={arrow} data-aos="fade-down" data-aos-duration="1800"  />
            </div>
            
        </Row>
    )
}

export default Service