import React, { useState, useEffect } from "react";
import { Navbar, Container, NavDropdown, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link'; // Import HashLink
import "./NavPage.css";
import logo from "../../asset/images/logos/logo.svg";
import enginiaBlack from "../../asset/images/logos/enginia-black.svg";
import enginiaWhite from "../../asset/images/logos/enginia-white.svg";

const NavPage = () => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 992);
  const [enginiaSrc, setEnginiaSrc] = useState(enginiaBlack);
  const [homeLogo, setHomeLogo] = useState("nav-home-logo");
  const [enginiaLogo, setEnginiaLogo] = useState("nav-home-enginia");
  const [togglerIconClass, setTogglerIconClass] = useState('navbar-toggler-icon-black');
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleLocationChange = () => {
      setIsNavbarOpen(false);
    };

    handleLocationChange(); // Close the navbar on initial render
    return () => {
      window.removeEventListener("scroll", handleLocationChange);
    };
  }, [location]);

  const showHomeLogo = location.pathname === "/home" || location.pathname === "/";
  const showNavbarBrand = !showHomeLogo || isSmallScreen;
  const isAboutPage = location.pathname === "/about" || location.pathname === "/ourteam";
  const navbarClass = `navbar navbar-expand-lg ${isScrolled ? "nav-scrolled" : ""} ${showHomeLogo ? "home-page-navbar" : ""} ${isAboutPage ? "inside-pages-nav" : ""}
  }`;

  useEffect(() => {
    if (isScrolled) {
      setEnginiaSrc(enginiaBlack);
      setTogglerIconClass('navbar-toggler-icon-black');
    } else if (isAboutPage) {
      setEnginiaSrc(enginiaWhite);
      setTogglerIconClass('navbar-toggler-icon-white');
    } else {
      setEnginiaSrc(showHomeLogo ? "" : enginiaBlack);
      setTogglerIconClass(showHomeLogo ? 'navbar-toggler-icon-white' : 'navbar-toggler-icon-black');
    }
  }, [isScrolled, isAboutPage, showHomeLogo]);

  useEffect(() => {
    setHomeLogo(showHomeLogo ? "nav-home-logo" : "nav-pages-logo");
  }, [showHomeLogo]);

  useEffect(() => {
    setEnginiaLogo(showHomeLogo ? "nav-home-enginia" : "nav-pages-enginia");
  }, [showHomeLogo]);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <div>
      <nav className={navbarClass}>
        <div className="container navbar-container">
          <Link reloadDocument className="navbar-brand" to="/home">
            <div className="logo-pages">
              <img src={logo} className={homeLogo} alt="Logo" />
              <img src={enginiaSrc} className={enginiaLogo} alt="Enginia Logo" />
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isNavbarOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span className={`navbar-toggler-icon ${togglerIconClass}`}></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`} id="navbarSupportedContent">
            <ul className="navbar-nav navbar-nav-cust">
              <li className="nav-item">
                <Link reloadDocument className={`nav-link ${location.pathname === "/home" ? "active" : ""}`} to="/home">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link reloadDocument className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link reloadDocument className={`nav-link ${location.pathname === "/ProjectsList" ? "active" : ""}`} to="/ProjectsList">
                  Projects
                </Link>
              </li>
              <NavDropdown title="Solutions" id="services-dropdown">
                <NavDropdown.Item href="/AiService" >
                    AI - Innovation
                </NavDropdown.Item>
                <NavDropdown.Item href="/erpsolutions">ERP Solutions</NavDropdown.Item>

                <NavDropdown.Item href="/MobileApps" >
                    Mobile Application Development 
                </NavDropdown.Item>

                <NavDropdown.Item href="/WebApplications" >
                    Web Application Development
                </NavDropdown.Item>
   
              </NavDropdown>
              <li className="nav-item">
                <HashLink className="nav-link" smooth to={"/home#contact"}>
                  Contact Us
                </HashLink>
              </li>
              <li className="nav-item">
                <Link reloadDocument className="nav-link"  to={"/ourteam"}>
                  Our Team
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavPage;
