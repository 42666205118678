import React, { useEffect, useState } from 'react';
import "./ProjectDetailsPage.css";
import { Hero, TechnologyStack, ProjectDetails, ProjectVideo } from "../../Features/ProjectDetails/index";
import { ContactUs } from "../../Features/ErpSolutions";
import { Container } from 'react-bootstrap';
import Color1 from '../../asset/images/vectors/color1.webp';
import Color2 from '../../asset/images/vectors/color2.webp';
import Color3 from '../../asset/images/vectors/color3.webp';
import Color4 from '../../asset/images/vectors/color4.webp';
import Color5 from '../../asset/images/vectors/color5.webp';

const ProjectDetailsPage = () => {
  const [data, setData] = useState({});
  const [dictValues, setDictValues] = useState({});

  const url = 'https://admin.enginia.ae';
  const apiVersion = '/api/v1';
  const pageUrl = window.location.pathname;
  const lastWordIndex = pageUrl.lastIndexOf('/') + 1;
  let pageName = pageUrl.substr(lastWordIndex);

  const options = {
    headers: {
      'Accept-Language': 'en',
    },
  };

  useEffect(() => {
    const fetchData = async (pageName) => {
      try {
        const apiUrl = `${url}${apiVersion}/${pageName}`;
        const response = await fetch(apiUrl, options);
        if (response.ok) {
          const responseData = await response.json();
          const templateFields = responseData.data.project_template?.fields || [];
          const dictionary = {};

          templateFields.forEach(item => {
            dictionary[item.key] = item.value;
          });

          setData(responseData.data);
          setDictValues(dictionary);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (pageUrl.toLowerCase().includes('projectdetails')) {
      let project_id = pageUrl.substr(lastWordIndex);
      pageName = `projects/${project_id}`;
    }

    fetchData(pageName);
  }, []);

  return (
    <div>
      <div className="inner-pages-top">
        <div className='color-div1'>
          <img id="color1" src={Color1} alt="color1" />
          <img id="color2" src={Color2} alt="color2" />
        </div>

        <Hero data={data} url={url} />
        <TechnologyStack data={data} url={url}></TechnologyStack>
        
        {Object.keys(dictValues).length > 0 && (
          <div className='color-div2'>
            <img id="color3" src={Color3} alt="color3" />
            <img id="color4" src={Color4} alt="color4" />
          </div>
        )}
        
        <Container>
          <ProjectDetails data={data} url={url} dictValues={dictValues}></ProjectDetails>
        </Container>
        {Object.keys(dictValues).length > 0 && (
        <div className='color-div3'>
          <img id="color5" src={Color5} alt="color5" />
        </div>
          )}
        <Container>
          <ProjectVideo data={data} url={url} dictValues={dictValues}></ProjectVideo>
        </Container>
        <ContactUs />
      </div>
    </div>
  );
}

export default ProjectDetailsPage;
