import React from 'react'
import './Services.css'
import { Service } from '../../index'
import { Container } from 'react-bootstrap'
import arrowRight from "../../../../asset/images/home/arrowright.svg"
import arrowLeft from "../../../../asset/images/home/arrowleft.svg"

const Services = () => {
    return (
        <Container className='home-services'>
            <Service
                id={"website"}
                title={"ERP Solutions"}
                para={"Welcome to Enginia ERP Solutions, your one-stop destination for comprehensive and customizable Open Source ERP solutions. Our flagship offering, ERPNext, is a robust platform designed to streamline operations across various departments and businesses of all sizes."}
                secondPara={"With ERPNext, you gain access to a versatile suite of modules, each tailored to address specific needs and challenges within your organization. From seamless Accounting and Purchase management to efficient Manufacturing processes and HR functionalities, our ERP solution covers it all."}
                image={require("../../../../asset/images/home/services/service1.png")}
                arrow={arrowLeft}
                dir={'right'}
                classImg={"first-service-img"}
                link={"/erpsolutions"}
                showLink={true}
           />
         
           <Service
                id={"mobile"}
                title={"Website Design"}
                para={"Transform your online presence with our expert website design services. From sleek aesthetics to seamless user experiences, we craft captivating websites that drive engagement and conversion."}
                image={require("../../../../asset/images/home/services/service2.png")}
                arrow={arrowRight}
                flexDir={"row-reverse"}
                classImg={"second-service-img"}
                link={"/WebApplications"}
                showLink={true}
           />
           
            <Service
                title={"Mobile Application"}
                para={"Enhance your digital reach with our custom mobile application development services. From intuitive user interfaces to seamless performance, we create engaging apps that elevate your brand and connect with your audience on the go."}
                image={require("../../../../asset/images/home/services/service3.png")}
                classImg={"third-service-img"}
                link={"/MobileApps"}
                showLink={true}
           />
        </Container>
    )
}

export default Services
