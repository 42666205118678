import React from "react";
import "./ErpSolutions.css";
import {
  Hero,
  MainFeatures,
  WhatWeDo,
  SpecialSolutions,
  Apps,
  SystemData,
  Discretion,
  Integrates,
} from "../../Features/ErpSolutions";
import { ContactUs } from "../../Features/Home";
import Color1 from "../../asset/images/vectors/color1.webp";
import Color2 from "../../asset/images/vectors/color2.webp";
import Color3 from "../../asset/images/vectors/color3.webp";
import Color4 from "../../asset/images/vectors/color4.webp";
import Color5 from "../../asset/images/vectors/color7.png";
import Color6 from "../../asset/images/vectors/color6.webp";

const ErpSolutions = () => {
  return (
    <div>
      <div className="color-div1">
        <img id="color1" src={Color1} alt="color1" />
        <img id="color2" src={Color2} alt="color2" />
      </div>
      <Hero />
      <Discretion />
      <WhatWeDo />
      <div className="color-div2">
        <img id="color3" src={Color3} alt="color3" />
        <img id="color4" src={Color4} alt="color4" />
      </div>
      <MainFeatures />
      <SpecialSolutions />
      <div className="color-div3">
      <img id="color5" src={Color5} alt="color5" />
        <img id="color6" src={Color6} alt="color6" />
      </div>
      <Integrates />
      <Apps />
      <SystemData />
      <ContactUs />
    </div>
  );
};

export default ErpSolutions;
