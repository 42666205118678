import React from 'react'
import "./MainFeatures.css"
import { Feature } from "../../index"
import { Container, Row, Col } from 'react-bootstrap'
import featureOne from "../../../../asset/images/ErpSolutions/Feature/featureOne.svg"
import featureTwo from "../../../../asset/images/ErpSolutions/Feature/featureTwo.svg"
import featureThree from "../../../../asset/images/ErpSolutions/Feature/featureThree.svg"
import featureFour from "../../../../asset/images/ErpSolutions/Feature/featureFour.svg"
import featureFive from "../../../../asset/images/ErpSolutions/Feature/featureFive.svg"
import featureSix from "../../../../asset/images/ErpSolutions/Feature/featureSix.svg"

const MainFeatures = () => {
    return (
        <Container className='mainfeatures'>
            <h1 className='mainfeatures-title'>The Main Features</h1>
            <Row>
                <Col md={6} lg={4}>
                    <Feature 
                        title={"Dashboards"}
                        para={"Take advantage of pre-built dashboards or design your own dashboard without one line of code"}
                        image={featureOne}
                    />
                </Col>
                <Col md={6} lg={4}>
                    <Feature 
                        title={"Data View Methods"}
                        para={"Calendar, Map, Chart, Report, Gant, Kanban,  and More . . ."}
                        image={featureTwo}
                    />
                </Col>
                <Col md={6} lg={4}>
                    <Feature 
                        title={"Advanced Reports"}
                        para={"All documents and modules are equipped with specialized reports ready for export and filtering"}
                        image={featureThree}
                    />
                </Col>
                <Col md={6} lg={4}>
                    <Feature 
                        title={"role Permission "}
                        para={"Simplify user access management with our intuitive permission rules feature."}
                        image={featureFour}
                    />
                </Col>
                <Col md={6} lg={4}>
                    <Feature 
                        title={"Print Format"}
                        para={"Refine your ERP's Print Format for streamlined data presentation."}
                        image={featureFive}
                    />
                </Col>
                <Col md={6} lg={4}>
                    <Feature 
                        title={"Notifications, multiple languages,  backup of your data and More . ."}
                        para={"Access content in multiple languages effortlessly, and ensure peace of mind with secure data backups."}
                        image={featureSix}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default MainFeatures
