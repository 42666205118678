import React from 'react'
import "./Hero.css"
import { Container, Row, Col } from 'react-bootstrap'
import arrowDown from "../../../../asset/images/svg/arrowDown.svg"
import sectionDown from "../../../../asset/images/svg/section-border.svg"
import logo from "../../../../asset/images/svg/enginiasolutions.svg"

const Hero = () => {
    return (
        <div className='home-hero'>
            <div className='home-hero-shadow'></div>
            <img id='left-light' src={require("../../../../asset/images/home/hero/left.webp")} />
            <img id='center-light'  src={require("../../../../asset/images/home/hero/center.webp")} />
            <Container >
                <Row>
                    <Col lg={6} className='home-hero-content' data-aos="fade-right" data-aos-duration="1500">
                        <img className='home-hero-logo' src={logo} />
                        <p className='home-hero-para'>
                            Enginia Solutions LLC in Dubai specializes in AI-driven IT solutions, enhancing decision-making with advanced web and mobile applications and customized ERP systems.
                        </p>
                        <a href='#netsystem' className='home-hero-link'>
                            OUR SERVICES
                        </a>
                    </Col>
                    <Col lg={6} className='home-hero-video-container' data-aos="fade-up" data-aos-duration="1800">
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            src={require("../../../../asset/images/video/hero.mp4")}
                            className='home-hero-video'
                        />
                    </Col>
                </Row>
            </Container>
            <img src={sectionDown} className='section-border' id='ai' />
        </div>
    )
}

export default Hero
