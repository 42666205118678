import React from 'react'
import "./Project.css"
import { Col } from "react-bootstrap"
import arrow from "../../../../asset/images/home/arrow.svg"
import { Link } from "react-router-dom"

const Project = ({ title, para, image, link }) => {
    return (
        <Col lg={4} md={6}>
            <Link to={`${link}`}>
                <div className="project">
                    <div className="project-content">
                        <h1>{title}</h1>
                        <p>{para}</p>
                        <img className='project-arrow' src={arrow} />
                    </div>
                    <img className='project-img' src={image} />
                </div>
            </Link>
        </Col>
    )
}

export default Project
