import React from 'react'
import "./SpecilaSolutions.css"
import arrowDown from "../../../../asset/images/ErpSolutions/SpecialSolutions/arrowDown.svg"
import { Container } from "react-bootstrap"

const SpecilaSolutions = () => {
    return (
        <Container className='special-solutions'>
            <h1 data-aos="fade-down" data-aos-duration="1800" className='special-solutions-title'>
                Our Special Solutions
                <img src={arrowDown} />
            </h1>
            <h1 data-aos="fade-down" data-aos-duration="2100" className='special-solutions-second-title'>Integrate with Facebook & Instagram & WhatsApp</h1>
            <p data-aos="fade-down" data-aos-duration="2200" className='special-solutions-para'>
                Integrate your website with Facebook and Instagram to empower users to post content directly from your platform. Additionally, enable the retrieval of old posts, comments, and reactions for data analysis, providing valuable insights into user engagement and behavior.
            </p>
            <div data-aos="fade-down" data-aos-duration="2200" className='special-solutions-video'>
                {/* <iframe className='special-solutions-iframe' src="https://www.youtube.com/embed/f4gPxUay7v0?si=i1K8mwoY-VI_AkwJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                <video className='special-solutions-iframe' controls src={require("../../../../asset/images/ErpSolutions/SpecialSolutions/special.mp4")} />
            </div>
        </Container>
    )
}

export default SpecilaSolutions
