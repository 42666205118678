import React from "react";
import "./MobileService.css";
import {
Hero,
AboutService,
TechnologyStack,
DevicesGallery,
MobileServices,
ProjectsList
} from "../../Features/MobileService";

const MobileService = () => {
  return (
    <div className="MobileService">
        <Hero/>
        <AboutService/>
        <DevicesGallery/>
        <TechnologyStack/>
        <MobileServices/>
        <ProjectsList/>
    </div>
  );
};

export default MobileService;
