import React from 'react';
import "./Project.css";
import arrow from "../../../../asset/images/ProjectsList/Arrow.svg"


const Project = ({ title,des, image }) => {
    return (
        <div className='projectsList-project-container'>
            <div className='projectsList-project-content' style={{ backgroundImage: `url(${image})` }}>
                {/* Content of your project */}
            </div>
            <div className='projectsList-project-container'>
                <div className='projectsList-project-title-container'>
                    <span>{title}</span>
                    <img src={arrow}></img>
                </div>
                <span className='projectsList-project-des'>{des}</span>
            </div>

        </div>
    );
};

export default Project;
