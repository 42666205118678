import React from 'react'
import "./Para.css"
import { Container } from 'react-bootstrap'

const Para = ({ title, desc, desc2, desc3, desc4 }) => {
    return (
        <div className='para-container'>
            <h1 className='para-title'>{title}</h1>
            <p className='para-desc'>{desc}</p>
            <p className='para-desc'>{desc2}</p>
            <p className='para-desc'>{desc3}</p>
            <p className='para-desc'>{desc4}</p>
        </div>
    )
}

export default Para
